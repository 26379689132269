// ================ Action types ================ //

import { get, post } from '../util/api';

export const DISABLE_SCROLLING = 'app/ui/DISABLE_SCROLLING';
export const GET_CURRENCY_RATES = 'app/ui/GET_CURRENCY_RATES';
export const CHANGE_MARKETPLACE_CURRENCY = 'app/ui/CHANGE_MARKETPLACE_CURRENCY';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  currencyRates: null,
  marketplaceCurrency: 'GBP',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case GET_CURRENCY_RATES: {
      const { currencyRates } = payload;
      return {
        ...state,
        currencyRates,
      };
    }

    case CHANGE_MARKETPLACE_CURRENCY: {
      const { marketplaceCurrency } = payload;
      return {
        ...state,
        marketplaceCurrency,
      };
    }

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

export const getCurrencyRatesRequest = currencyRates => {
  return {
    type: GET_CURRENCY_RATES,
    payload: { currencyRates },
  };
};

export const changeMarketplaceCurrencyRequest = newCurrency => {
  return {
    type: CHANGE_MARKETPLACE_CURRENCY,
    payload: { marketplaceCurrency: newCurrency },
  };
};
// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.ui;
  return disableScrollRequests.some(r => r.disableScrolling);
};

// ================ Thunks ================ //

export const fetchCurrencyRates = () => dispatch => {
  return get('/api/currency')
    .then(resp => {
      const rates = resp?.rates || {};

      const requiredCurrencies = ['GBP', 'EUR', 'USD', 'RON', 'BGN'];

    // Filter the rates object
    const filteredRates = Object.keys(rates)
        .filter(key => requiredCurrencies.includes(key))
        .reduce((obj, key) => {
            obj[key] = rates[key];
            return obj;
        }, {});

      dispatch(getCurrencyRatesRequest(filteredRates));
    })
    .catch(e => console.log(e));
};

export const changeMarketplaceCurrency = newCurrency => dispatch => {
  dispatch(changeMarketplaceCurrencyRequest(newCurrency));
};
