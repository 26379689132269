import React from 'react';
import css from './SearchBar.module.css';
import { Button, Menu, MenuContent, MenuItem, MenuLabel, NamedLink } from '../../../../components';
import { listingFields } from '../../../../config/configListing';

const SearchBar = () => {
  const allCategories = listingFields.find(i => i.key === 'category')?.enumOptions || [];

  const allBrands1 = listingFields.find(i => i.key === 'brand')?.enumOptions || [];
  const allBrands = allBrands1.slice(0, 20);

  const links = [
    // {
    //   dropdown: true,
    //   component: (
    //     <Menu>
    //       <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
    //         <span>Nike</span>
    //       </MenuLabel>
    //       <MenuContent className={css.profileMenuContent}>
    //         <MenuItem key={'singleMenu'}>
    //           <div className={css.singleMenuWrapper}>
    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_brand=nike`,
    //               }}
    //             >
    //               {'Nike'}
    //             </NamedLink>

    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_brand=adidas`,
    //               }}
    //             >
    //               {'Adidas'}
    //             </NamedLink>

    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_brand=puma`,
    //               }}
    //             >
    //               {'Puma'}
    //             </NamedLink>
    //           </div>
    //         </MenuItem>
    //       </MenuContent>
    //     </Menu>
    //   ),
    // },
    {
      label: 'Nike',
      search: '?pub_brand=nike',
    },
    {
      label: 'Adidas',
      search: '?pub_brand=adidas',
    },
    {
      label: 'Puma',
      search: '?pub_brand=puma',
    },
    {
      label: 'New Balance',
      search: '?pub_brand=new-balance',
    },
    {
      label: 'Mizuno',
      search: '?pub_brand=mizuno',
    },
    {
      label: 'Other',
      search: '?pub_brand=other',
    },

    // {
    //   dropdown: true,
    //   component: (
    //     <Menu>
    //       <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
    //         <span>Keys & Synths</span>
    //       </MenuLabel>
    //       <MenuContent className={css.profileMenuContent}>
    //         <MenuItem key={'singleMenu'}>
    //           <div className={css.singleMenuWrapper}>
    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_category=keyboards`,
    //               }}
    //             >
    //               {'Keyboards'}
    //             </NamedLink>

    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_category=synths`,
    //               }}
    //             >
    //               {'Synthesizers'}
    //             </NamedLink>

    //             <NamedLink
    //               className={css.singleMenuOption}
    //               name="SearchPage"
    //               to={{
    //                 search: `?pub_category=pianos`,
    //               }}
    //             >
    //               {'Pianos'}
    //             </NamedLink>
    //           </div>
    //         </MenuItem>
    //       </MenuContent>
    //     </Menu>
    //   ),
    // },

    // {
    //   label: 'Recording Equipment',
    //   search: '?pub_category=recording-and-studio-equipment',
    // },

    // {
    //   dropdown: true,
    //   component: (
    //     <Menu>
    //       <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
    //         <span>All categories</span>
    //       </MenuLabel>
    //       <MenuContent className={css.profileMenuContent}>
    //         <MenuItem key={'singleMenu'}>
    //           <div className={css.doubleMenuWrapper}>
    //             {allCategories.map(c => {
    //               return (
    //                 <NamedLink
    //                   className={css.menuOption}
    //                   name="SearchPage"
    //                   to={{
    //                     search: `?pub_category=${c.option}`,
    //                   }}
    //                 >
    //                   {c.label}
    //                 </NamedLink>
    //               );
    //             })}
    //           </div>
    //         </MenuItem>
    //       </MenuContent>
    //     </Menu>
    //   ),
    // },

    // {
    //   dropdown: true,
    //   component: (
    //     <Menu>
    //       <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
    //         <span>Brands</span>
    //       </MenuLabel>
    //       <MenuContent className={css.profileMenuContent}>
    //         <MenuItem key={'singleMenu'}>
    //           <div className={css.doubleMenuWrapper}>
    //             {allBrands.map(c => {
    //               return (
    //                 <NamedLink
    //                   className={css.menuOption}
    //                   name="SearchPage"
    //                   to={{
    //                     search: `?pub_brand=${c.option}`,
    //                   }}
    //                 >
    //                   {c.label}
    //                 </NamedLink>
    //               );
    //             })}
    //           </div>
    //         </MenuItem>
    //       </MenuContent>
    //     </Menu>
    //   ),
    // },

    {
      label: 'Stores',
      search: '?pub_userType=store',
    },

    {
      label: 'Seller shops',
      search: '?pub_userType=seller',
    },
  ];
  return (
    <div className={css.wrapper}>
      <div className={css.wrapperLeft}>
        {links.map(l => {
          if (l.dropdown) {
            return l.component;
          }
          return (
            <NamedLink
              className={css.link}
              name="SearchPage"
              to={{
                search: l.search,
              }}
            >
              {l.label}
            </NamedLink>
          );
        })}
      </div>
      <div className={css.wrapperRight}>
        <NamedLink name="NewListingPage" className={css.newListingLink}>
          Sell
        </NamedLink>
      </div>
    </div>
  );
};

export default SearchBar;
