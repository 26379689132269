import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';

// let ReactPixel;
// if (typeof window !== 'undefined') {
//   ReactPixel = require('react-facebook-pixel');
// }


import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { useConfiguration } from '../../../context/configurationContext';
import { listingFields } from '../../../config/configListing';
import { UilShieldCheck } from '@iconscout/react-unicons';
import { UilCardAtm } from '@iconscout/react-unicons';
import { UilComment } from '@iconscout/react-unicons';
import CurrencySelector from '../CurrencySelector/CurrencySelector';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  const [reactPixel, setReactPixel] = useState(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  // useEffect(() => {
  //   import('react-facebook-pixel')
  //     .then(module => module.default)
  //     .then(ReactPixel => {
  //       ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
  //       //ReactPixel.pageView()
  //       setReactPixel(ReactPixel);
  //     })
  // }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="FavListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavListingsPage'))}
            name="FavListingsPage"
          >
            <span className={css.menuItemBorder} />
            <span>Favorite listings</span>
          </NamedLink>
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.buttonLink}>
      <span className={css.signup}
        onClick={() => {
          if (typeof window !== 'undefined') {
            if (reactPixel) {
              reactPixel.trackCustom('SignupPage', {
                action: 'click',
                page: 'TopbarDesktop',
              });
            }
          }
        }}
      >
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.buttonLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  const brandOptions = listingFields.find(i => i.key === 'brand')?.enumOptions || [];

  const brandsMenu = (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span className={css.menuLabel}>Brands</span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key={'doubleMenu'}>
          <div className={css.doubleMenuWrapper}>
            {brandOptions.slice(0, 15).map(b => {
              return (
                <NamedLink
                  className={css.menuOption}
                  name="SearchPage"
                  to={{
                    search: `?pub_brand=${b.option}`,
                  }}
                >
                  {b.label}
                </NamedLink>
              );
            })}
          </div>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  return (
    <>
      {/* <div className={css.iconsSection}>
        <div className={css.iconWrapper}>
          <UilShieldCheck /> Buyer Protection Guarantee
        </div>
        <div className={css.iconWrapper}>
          <UilCardAtm /> Secure Payments
        </div>

        <div className={css.iconWrapper}>
          <UilComment /> Customer support
        </div>
      </div> */}
      <nav className={classes}>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        {search}
        {/* <NamedLink className={css.createListingLink} name="NewListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink> */}
        {/* {inboxLink} */}
        {/* {brandsMenu}
        <NamedLink
          className={css.topbarLink}
          // name="StoresPage"
          name="SearchPage"
          to={{
            search: `?pub_userType=store`,
          }}
        >
          {'Stores'}
        </NamedLink>
        <NamedLink
          className={css.topbarLink}
          name="SearchPage"
          to={{
            search: `?pub_userType=artist`,
          }}
        >
          {'Artist Shop'}
        </NamedLink> */}
        <CurrencySelector />
        {profileMenu}
        {signupLink}
        {loginLink}
      </nav>
    </>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
