import React from 'react';
import { changeMarketplaceCurrency } from '../../../ducks/ui.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Menu from '../../Menu/Menu';
import MenuLabel from '../../MenuLabel/MenuLabel';
import MenuContent from '../../MenuContent/MenuContent';
import MenuItem from '../../MenuItem/MenuItem';
import css from './CurrencySelector.module.css';

const CurrencySelectorComponent = props => {
  const { onChangeMarketplaceCurrency, marketplaceCurrency, currencyRates } = props;
  if (!currencyRates) {
    return null;
  }
  const currencyRatesLabels = Object.keys(currencyRates);
  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <span className={css.menuLabel}>{marketplaceCurrency}</span>
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {currencyRatesLabels.map(l => {
          return (
            <MenuItem className={css.menuItem} key={l}>
              <span onClick={() => onChangeMarketplaceCurrency(l)}>{l}</span>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onChangeMarketplaceCurrency: newCurrency => dispatch(changeMarketplaceCurrency(newCurrency)),
});

const mapStateToProps = state => {
  const { marketplaceCurrency, currencyRates } = state.ui || {};

  return { marketplaceCurrency, currencyRates };
};

const CurrencySelector = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CurrencySelectorComponent);

export default CurrencySelector;
