/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { userTypes } from './general';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   key: 'userType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   // includeForListingTypes: ['hide'],
  //   enumOptions: userTypes
  //     .filter(t => t.searchable)
  //     .map(i => {
  //       return { option: i.option, label: i.label };
  //     }),
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //     requiredMessage: 'You need to select an option.',
  //   },
  // },
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'boots', label: 'Boots' },
  //     { option: 'other', label: 'Other' },
  //     // { option: 'band-and-orchestra', label: 'Band and Orchestra' },
  //     // { option: 'amps', label: 'Amplifiers' },
  //     // { option: 'bass-guitars', label: 'Bass guitars' },
  //     // { option: 'cables', label: 'Cables' },
  //     // { option: 'cases', label: 'Cases' },
  //     // { option: 'dj-gear', label: 'DJ gear' },
  //     // { option: 'drums-and-percussion', label: 'Drums and Percussion' },
  //     // { option: 'electric-guitars', label: 'Electric guitars' },
  //     // { option: 'folk-instruments', label: 'Folk Instruments' },
  //     // { option: 'keyboards', label: 'Keyboards' },
  //     // { option: 'synths', label: 'Synthesizers' },
  //     // { option: 'pianos', label: 'Pianos' },
  //     // { option: 'microphones', label: 'Microphones' },
  //     // { option: 'pedals', label: 'Pedals' },
  //     // { option: 'pro-audio-and-software', label: 'Pro Audio and Software' },
  //     // { option: 'recording-and-studio-equipment', label: 'Recording and Studio Equipment' },
  //     // { option: 'speakers-and-monitors', label: 'Speakers and Monitors' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },

  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',

  //   enumOptions: [
  //     { option: 'nike', label: 'Nike' },
  //     { option: 'adidas', label: 'Adidas' },
  //     { option: 'puma', label: 'Puma' },
  //     { option: 'new-balance', label: 'New Balance' },
  //     { option: 'mizuno', label: 'Mizuno' },
  //     { option: 'other', label: 'Other' },


  //     // // not visible
  //     // { option: 'evh', label: 'EVH' },
  //     // { option: 'fulltone', label: 'Fulltone' },
  //     // { option: 'prophet', label: 'Prophet' },
  //     // { option: 'tanglewood', label: 'Tanglewood' },
  //     // { option: 'yamaha', label: 'Yamaha' },
  //     // { option: 'silvertone', label: 'Silvertone' },
  //     // { option: 'bk_butler', label: 'BK Butler' },
  //     // { option: 'dunlop', label: 'Dunlop' },
  //     // { option: 'soundcraft', label: 'Soundcraft' },
  //     // { option: 'blackstar', label: 'Blackstar' },
  //     // { option: 'washburn', label: 'Washburn' },
  //     // { option: 'godin', label: 'Godin' },
  //     // { option: 'tascam', label: 'Tascam' },
  //     // { option: 'esp', label: 'ESP' },
  //     // { option: 'stafford_and_sons', label: 'Stafford & Sons' },
  //     // { option: 'behringer', label: 'Behringer' },
  //     // { option: 'rickenbacker', label: 'Rickenbacker' },
  //     // { option: 'native_instruments', label: 'Native Instruments' },
  //     // { option: 'elektron', label: 'Elektron' },
  //     // { option: 'pioneer', label: 'Pioneer' },
  //     // { option: 'technics', label: 'Technics' },
  //     // { option: 'nord', label: 'Nord' },
  //     // { option: 'kawai', label: 'Kawai' },
  //     // { option: 'mapex', label: 'Mapex' },
  //     // { option: 'rat', label: 'RAT' },
  //     // { option: 'ehx', label: 'EHX' },
  //     // { option: 'kevler', label: 'Kevler' },
  //     // { option: 'clifton', label: 'Clifton' },
  //     // { option: 'crazy_tube', label: 'Crazy Tube' },
  //     // { option: 'samson', label: 'Samson' },
  //     // { option: 'arturia', label: 'Arturia' },
  //     // { option: 'burns', label: 'Burns' },
  //     // { option: 'logitech', label: 'Logitech' },
  //     // { option: 'strymon', label: 'Strymon' },
  //     // { option: 'wampler', label: 'Wampler' },
  //     // { option: 'diago', label: 'Diago' },
  //     // { option: 'dr_z', label: 'Dr.Z' },
  //     // { option: 'pedaltrain', label: 'Pedaltrain' },
  //     // { option: 'takamine', label: 'Takamine' },
  //     // { option: 'friedman', label: 'Friedman' },
  //     // { option: 'aguilar', label: 'Aguilar' },
  //     // { option: 'red_sound', label: 'Red Sound' },
  //     // { option: 'italia', label: 'Italia' },
  //     // { option: 'sweet_sound', label: 'Sweet Sound' },
  //     // { option: 'dallas_arbiter', label: 'Dallas Arbiter' },
  //     // { option: 'dy_guitars', label: 'DY Guitars' },
  //     // { option: 'intellijel', label: 'Intellijel' },
  //     // { option: 'microtech_gefell', label: 'Microtech Gefell' },
  //     // { option: 'trax', label: 'Trax' },
  //     // { option: 'cloud', label: 'Cloud' },
  //     // { option: 'moog', label: 'Moog' },
  //     // { option: 'akai', label: 'Akai' },
  //     // { option: 'focusrite', label: 'Focusrite' },
  //     // { option: 'moog', label: 'Moog' },
  //     // { option: 'shure', label: 'Shure' },
  //     // { option: 'tama', label: 'TAMA' },
  //     // { option: 'sabian', label: 'Sabian' },
  //     // { option: 'zildjian', label: 'Zildjian' },
  //     // { option: 'pearl', label: 'Pearl' },
  //     // { option: 'alesis', label: 'Alesis' },
  //     // { option: 'keeley', label: 'Keeley' },
  //     // { option: 'ludwig', label: 'Ludwig' },
  //     // { option: 'novation', label: 'Novation' },
  //     // { option: 'eastman', label: 'Eastman' },
  //     // { option: 'cort', label: 'Cort' },
  //     // { option: 'guild', label: 'Guild' },
  //     // { option: 'ernie_ball_music_man', label: 'Ernie Ball Music Man' },
  //     // { option: 'cordoba', label: 'Cordoba' },
  //     // { option: 'line_6', label: 'Line 6' },
  //     // { option: 'revelation', label: 'Revelation' },
  //     // { option: 'fernandes_burny', label: 'Fernandes / Burny' },
  //     // { option: 'hagstrom', label: 'Hagstrom' },
  //     // { option: 'tokai', label: 'Tokai' },
  //     // { option: 'art_lutherie', label: 'Art & Lutherie' },
  //     // { option: 'breedlove', label: 'Breedlove' },
  //     // { option: 'manson', label: 'Manson' },
  //     // { option: 'ozark', label: 'Ozark' },
  //     // { option: 'sigma', label: 'Sigma' },
  //     // { option: 'other', label: 'Other' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Brand',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'condition',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [{ option: 'new', label: 'New' }, { option: 'used', label: 'Used' }],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Condition',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Condition',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Condition',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a condition.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'product-selling',
    label: 'Sell bicycles',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'oneItem',
  },
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
